.header-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 65px;
    background: #F9F8F4;
    width: 100%;
    font-size: 20px;
    color: rgba(30, 30, 30, 0.50);
    font-weight: 700;
    @media (max-width: 500px){
        padding: 0px 16px;
    }   
    .networks{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        cursor: pointer;
    }
    .logo{
        flex-grow: 1;
        max-width: 440px;
        max-height: 178px;
        min-width: 280px;
        cursor: pointer;
        @media (max-width: 769px){
            max-width: 200px;
            min-width: auto;
        }
    }
    nav{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 32px;
        cursor: pointer;
        div{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
        }
    }
    .menuBox{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        position: absolute;
        right: 32px;
        top: 80px;
        background: #F9F8F4;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 20px;
        z-index: 5;
        div{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
        }
    }
}

.language-box{
    position: absolute;
    right: 66px;
    top: 120px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    background: #F9F8F4;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-weight: 700;
    div{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
    }
}

.language-box-tablet{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px !important;
}