@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,500;0,700;1,500&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Josefin Sans', sans-serif;
    list-style: none;
    user-select: none;
}

body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #F9F8F4;
}
#root{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

@import "./components/gallery.scss";
@import "./components/header.scss";
@import "./components/footer.scss";
@import "./components/home.scss";
@import "./components/detail.scss";
@import "./components/bio.scss";
@import "./components/contact.scss";