.home-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 64px;
    color: rgba(30, 30, 30, 0.50);
    font-size: 16px;
    font-weight: 700;   
    flex-grow: 1;
    .sections{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px;
        margin-bottom: 64px;
        cursor: pointer;
        h2{
            font-size: 20px;
        }
    }
    .section-tablet{
        position: relative;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 35px;
        cursor: pointer;
        .principal{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            color: #1E1E1E;
        }
        .box{
            position: absolute;
            left: 50%;
            bottom: -55px;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: #F9F8F4;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            padding: 10px 30px 5px 30px; 
            gap: 20px; 
            z-index: 4;  
        }
    }
    &.tablet{
        padding-top: 33px;
    }
}