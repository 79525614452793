.my-masonry-grid {
    display: flex;
    width: auto;
    column-gap: 30px;
    max-width: 1600px;
    width: 100%;
    padding: 0px 32px;
    margin-bottom: 90px;
    @media (max-width:560px){
        column-gap: 16px;
        padding: 0px 16px;
    }
}
.my-masonry-grid_column {
    background-clip: padding-box;
    max-width: 512px !important;
    div{
        overflow: hidden !important;
        img:hover{
            transform: scale(1.3);
            transition: all 0.5s ease-in-out;
            cursor: pointer;
        }
    }
}
.my-masonry-grid_column-galery {
    background-clip: padding-box;
    div{
        overflow: hidden !important;
        img:hover{
            transform: scale(1.3);
            transition: all 0.5s ease-in-out;
            cursor: pointer;
        }
    }
}

.image-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    cursor: pointer;
}

.image-container img {
    width: 100%;
    transition: all 0.5s ease-in-out;
    pointer-events: none !important;
}

.image-container:hover img {
    transform: scale(1.3);
    filter: brightness(0.7);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.image-container:hover .overlay {
    opacity: 1;
}

.overlay h3, .overlay p {
    color: white;
    margin: 5px 0;
    text-align: center;
}