.footer-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 65px 32px 65px;
    border-top: 1px solid black;
    color: rgba(30, 30, 30, 0.75);
    font-weight: 500;
    width: 95%;
    @media (max-width:620px){
        padding: 10px 16px;
        padding-bottom: 15px;
        margin: 0px 16px;
    }
    @media (max-width: 310px){
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }
    img{
        cursor: pointer;
    }
    span{
        font-weight: 700;   
        cursor: pointer;
    }
    .networks{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        cursor: pointer;
    }
    .logo{
        width: 190px;
        @media (max-width:700px){
            width: 120px;
        }
    }
    &.tablet{
        .erick{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @media (max-width:620px){
                font-size: 10px;
            }
        }
    }
    &.mobile{
        padding: 12px 16px;
        margin: 0px;
    }
}