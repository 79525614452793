.contact-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 90px;
    flex-grow: 1;
    @media (max-width: 768px){
        margin-top: 60px;
    }
    h1{
        font-size: 36px;
        font-weight: 700;
    }

    .box{
        max-width: 924px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 88px;
        width: 100%;
        margin: auto;
        margin-top: 100px;
        margin-bottom: 100px;
        padding: 0px 32px;
        @media (max-width: 768px){
            max-width: 435px;
            margin-top: 48px;
        }
        @media (max-width: 420px){
            margin-bottom: 5px;
        }
        .form{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            justify-content: center;
            gap: 48px;
            div{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                label{
                    font-size: 20px;
                    font-weight: 700;
                    @media (max-width: 768px){
                        font-size: 16px;
                    }
                }
                input{
                    all: unset;
                    border-bottom: 2px solid #1E1E1E;
                    width: 100%;
                    font-size: 20px;
                    font-weight: 500;
                    margin-top: 10px;
                    padding-left: 12px;
                    @media (max-width: 420px){
                        font-size: 16px;
                    }
                }
                textarea {
                    border: none;
                    background: none;
                    margin: 0;
                    padding: 0;
                    outline: none;
                    border-bottom: 2px solid #1E1E1E;
                    width: 100%;
                    font-size: 20px;
                    font-weight: 500;
                    margin-top: 10px;
                    padding-left: 12px;
                    resize: none;
                    height: 84px;
                    @media (max-width: 420px){
                        font-size: 16px;
                    }
                }
                .error{
                    color: red;
                    margin-top: 5px;
                }
            }
            .button{
                border-radius: 10px;
                border: 2px solid #9CCCA5;
                background: #9CCCA5;
                font-size: 20px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0.5px;
                padding: 16px;
                width: fit-content;
                align-self: center;
                cursor: pointer;
            }
        }
    }
    .illustration{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 32px;
        img{
            width: 100%;
            max-width: 350px;
            pointer-events: none;
            @media (max-width: 420px){
                max-width: 200px;
            }
        }
    }
}