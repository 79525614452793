.detail-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 0px 32px;
    padding-top: 64px;
    background: #F9F8F4;
    flex-grow: 1;
    @media (max-width:801px){
        padding: 0px 16px;
        padding-top: 32px;
    }
    .backTo{
        align-self: flex-start;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
        @media (max-width:801px){
            font-size: 14px;
        }
        span{
            font-weight: 700;
        }
    }
    .box{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 32px;
        margin-top: 64px;
        width: 100%;
        @media (max-width:801px){
            flex-direction: column;
            margin-top: 32px;
        }
        .photos{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 14px;
            row-gap: 32px;
            flex-wrap: wrap;
            @media (max-width:801px){
                width: 100%;
                max-width: 350px;
                margin: auto;
            }
            img{
                width: 100%;
                max-width: 789px;
                pointer-events: none;
                @media (max-width:801px){
                    max-width: none;
                }
            }
        }
        .info{
            width: 50%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            @media (max-width:801px){
                width: 100%;
            }
            h1{
                font-size: 48px;
                font-weight: 700;
                margin-bottom: 32px;
            }
            h2{
                font-size: 20px;
                font-weight: 500;
                padding-bottom: 32px;
                border-bottom: 1px solid #1E1E1E;
                width: 100%;
            }
            p{
                font-size: 20px;
                font-weight: 500;
                line-height: 150%;
                margin-top: 32px;
                @media (max-width:801px){
                    font-size: 14px;
                }
            }
            h5{
                width: 100%;
                text-align: center;
                font-size: 28px;
                font-weight: 700;
                margin-top: 120px;
            }
            img{
                max-width: 215px;
                align-self: center;
                margin-top: 32px;
            }
        }
        .infoDetail800{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            gap: 20px;
            width: 100%;
            h1{
                font-size: 24px;
                font-weight: 700;
            }
            h2{
                font-size: 12px;
                font-weight: 500;
                border-bottom: 1px solid #1E1E1E;
                width: 70%;
                padding-bottom: 10px;
                @media (max-width:500px){
                    width: 100%;
                }
                
            }
        }
        .collaborator-detail800{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 64px;
            width: 100%;
            h5{
                font-size: 14px;
                font-weight: 700;
            }
            img{
                max-width: 110px;
                width: 100%;
                margin-top: 32px;
            }
        }

    }
    .proceso-creativo{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 64px;
        h2{
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 64px;
        }
        p{
            font-size: 24px;
            font-weight: 500;
            line-height: 150%;
            @media (max-width:680px){
                font-size: 18px;
            }
            @media (max-width:390px){
                font-size: 16px;
            }
        }
        div{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 32px;
            margin-top: 64px;
            img{
                height: 320px;
                pointer-events: none;
            }
            @media (max-width: 1000px){
                gap: 16px;
                img{
                    height: 200px;
                }
            }
        }
    }
    .next-prev{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-top: 33px;
        margin-bottom: 33px;
        div{
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: 500;
            color: #1E1E1E;
            cursor: pointer;
            @media (max-width:801px){
                font-size: 16px;
            }
        }
        .next{
            img{
                transform: rotateY(180deg);
            }
        }
    }
}

