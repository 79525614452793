.bio-container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 145px;
    gap: 64px;
    margin: 0px 64px;
    padding-bottom: 96px;
    padding-top: 140px;
    border-bottom: 1px solid #1E1E1EBF;
    flex-grow: 1;
    .mainPhoto{
        max-width: 654px;
        width: 100%;
        min-width: 400px;
    }

    .info{
        width: 880px;
        min-width: 550px;
        h2{
            font-size: 32px;
            font-weight: 700;
            color: #1E1E1E;
            margin-bottom: 48px;
        }
        .box1{
            padding-bottom: 48px;
            border-bottom: 1px solid #1E1E1EBF;
            margin-bottom: 48px;
            p{
                font-style: italic;
                font-size: 24px;
                font-weight: 500;
                line-height: 150%;
            }
        }
        .box2{
            ul{
                display: flex;
                flex-direction: column;
                li{
                    line-height: 150%;
                    font-weight: 500;
                    font-size: 24px;
                    span{
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

.bio-container-changeDesktop{
    margin-top: 43px;
    padding: 0px 32px;
    @media (max-width:620px){
        padding: 0px 16px;
        margin-top: 20px;
    }
    .box1{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        margin-bottom: 32px;
        @media (max-width:620px){
            flex-direction: column;
            align-items: center;
            margin-bottom: 48px;
        }
        div{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            @media (max-width:620px){
                align-items: center;
            }
            h2{
                margin-bottom: 20px;
                font-size: 20px;
                font-weight: 700;
                @media (max-width:620px){
                    margin-top: 48px;
                    font-size: 18px;
                }
            }
            p{
                font-size: 16px;
                font-weight: 500;
                font-style: italic;
                line-height: 150%;
                max-width: 430px;
                @media (max-width:620px){
                    text-align: center;
                    font-size: 14px;
                }
            }
        }
        .mainPhoto{
            max-width: 300px;
            width: 100%;
            min-width: 252px;
            @media (max-width:620px){
                max-width: 360px;
            }
        }
    }
    .box2{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 32px 0px;
        max-width: 745px;
        margin: auto;
        border-top: 2px solid #1E1E1E;
        border-bottom: 2px solid #1E1E1E;
        @media (max-width:620px){
            align-items: center;
            padding: 48px 0px;
        }
        h2{
            margin-bottom: 20px;
            @media (max-width:620px){
                font-size: 18px;
            }
        }
        ul{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 8px;
            li{
                line-height: 150%;
                font-weight: 500;
                font-size: 16px;
                @media (max-width:620px){
                    font-size: 14px;
                }
                span{
                    font-weight: 700;
                }
            }
        }
    }
}

.clients-container{
    padding-top: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 48px;
    padding-top: 96px;
    h2{
        font-size: 40px;
        font-weight: 700;
        color: #1E1E1E;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width:620px){
            font-size: 18px;
        }
    }
    div{
        margin-top: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 40px;
        max-width: 1450px;
        img{
            width: 216px;
        }
    }
    .finalPhoto{
        max-width: 343px;
        width: 30%;
        align-self: flex-end;
        margin-top: 64px;
        pointer-events: none;
        @media (max-width:620px){
            width: 100%;
            max-width: 110px;
            margin-top: 60px;
        }
    }
    &.changeDesktop{
        @media (max-width:620px){
            padding: 0px 16px;
            padding-top: 32px;
        }
        div{
            gap: 25px;
            @media (max-width:620px){
                gap: 16px;
            }
            img{
                width: 130px;
                @media (max-width:620px){
                    width: 90px;
                }
            }
        }
    }
}